import Particles from "./Particles.js";

export const Particle = Particles;

const aVW = (val, cw) => val * (cw / 100);
const aVH = (val, ch) => val * (ch / 100);

export const defaultParticleConfig = (cw, ch) => ({
  desktop: {
    nameFont: `bold ${aVW(15, cw)}px sans-serif`,
    nameCoords: [cw / 2.5, ch / 2],
    nameStep: Math.round(cw / 125),
    particleSize: () => Math.random() * aVW(0.15, cw) + aVW(0.2, cw),
  },
  mobile: {
    nameFont: `bold ${aVW(25, cw)}px sans-serif`,
    nameCoords: [
      [cw / 2, aVH(40, ch)],
      [cw / 2, aVH(55, ch)],
    ],
    nameStep: Math.round(cw / 55),
    particleSize: () => Math.random() * aVW(0.15, cw) + aVW(0.25, cw),
  },
});

export const COLORS = ["#ee4035", "#f37736", "#fdf498", "#7bc043", "#0392cf"];

export default { Particle, COLORS, constraints: defaultParticleConfig };
